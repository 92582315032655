import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './Sidebar.css'; // Facultatif si vous utilisez des styles supplémentaires

const sidebarImage = '/vinymatic.png';

const Sidebar = () => {
    const [rubriques, setRubriques] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);

    useEffect(() => {
        axios.get('https://support.vinymatic.com/api/rubriques/')
            .then(response => setRubriques(response.data))
            .catch(error => console.error('Erreur lors de la récupération des rubriques:', error));
    }, []);

    const toggleSidebar = () => setIsOpen(!isOpen);

    const handleLinkClick = () => setIsOpen(false);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest('.btn')) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    return (
        <>
            {/* Bouton de toggle */}
            <button
                className={`btn btn-primary d-lg-none ${isOpen ? 'open' : ''}`}
                onClick={toggleSidebar}
            >
                <FaBars />
            </button>

            {/* Sidebar */}
            <div
                ref={sidebarRef}
                className={`sidebar ${isOpen ? 'd-block' : 'd-none'} d-lg-block position-fixed top-50 start-0 translate-middle-y h-75 w-20 overflow-auto`}
                style={{ width: '225px', overflowY: 'auto' }}
            >
                <ul className="nav flex-column px-3">
                    {rubriques.map(rubrique => (
                        <li className="nav-item mb-3" key={rubrique.id}>
                            <strong>{rubrique.title}</strong>
                            <ul className="nav flex-column ms-3">
                                {rubrique.sous_rubriques.map(sous => (
                                    <li className="nav-item" key={sous.id}>
                                        {sous.title}
                                        <ul className="nav flex-column ms-3">
                                            {sous.sous_sous_rubriques.map(sousSous => (
                                                <li className="nav-item" key={sousSous.id}>
                                                    <Link
                                                        className="nav-link text-dark"
                                                        to={`/content/${sousSous.id}`}
                                                        onClick={handleLinkClick}
                                                    >
                                                        {sousSous.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
