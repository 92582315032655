import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';

import Sidebar from './components/Sidebar';
import SousSousRubriquePage from './components/SousSousRubriquePage';
import HomePage from './components/HomePage';
import './App.css';  // Assurez-vous que Bootstrap est importé ici

function App() {
    return (
        <Router>
            <Header /> {/* Ajout du Header */}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <Routes>
                            <Route path="/" element={<HomePage />} />  {/* Utiliser le nouveau composant */}
                            <Route path="/content/:id" element={<SousSousRubriquePage />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
